import React, { useState, useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import {
  Button,
  Link,
  Grid,
  Divider,
  Card,
  Typography,
  CardContent
} from '@material-ui/core';
import {
  SendOutlined,
  SaveOutlined,
  ArchiveOutlined,
  CancelOutlined,
  DeleteForever
} from '@material-ui/icons';

import { useAuth } from '../../context/AuthContext';
import { useFormState, useFormDispatch } from '../../context/FormContext';
import { useQuery } from '@apollo/react-hooks';
import {
  CREATE_PPE,
  UPSERT_RESPONSES,
  SUBMIT_EVAL,
  SAVE_EVAL,
  PUBLISH_EVAL,
  UNPUBLISH_EVAL,
  ARCHIVE_EVAL,
  UPSERT_COMMENTS,
  SOFT_DELETE_PPE,
  DELETE_COMMENTS,
  ARCHIVE_DATA
} from '../../graphql/evalMutations';
import { FETCH_LAST_MODIFIED_DATE } from '../../graphql/evalQueries';
import { UPSERT_NEXT_EVAL_DATE_FOR_USER } from '../../graphql/nextEval';
import { FETCH_COMPETENCY_FOR_USER } from '../../graphql/competencies';
import {
  STATUS,
  MODAL,
  ACTION,
  FORM_TYPE,
  NEXT_EVAL_DATE_QUESTION_TEXT,
  getCompetencyUrl
} from '../../helpers/constants';
import { AutoScroll, LoadingSpinner } from '../common';
import {
  SubmitModal,
  ArchiveModal,
  CancelModal,
  DeleteModal,
  ErrorModal
} from './FormModals';
import AuditField from './AuditField';
import PublishSection from './PublishSection';
import QuestionContainer from './Question';
import CommentSection from './comments/CommentSection';
import PrintPDFButton from '../PrintPDFButton';
import AssignToField from './AssignToField';
import RatingScale from './RatingScale';
import StatusBanner from './StatusBanner';
import {
  evalFormattedResponses,
  filterOutDeletedComments,
  formatEvalComment
} from '../../helpers/evalGenerationHelpers';
import {
  userIsAdmin,
  userIsPrimary,
  userIsManager
} from '../../helpers/roleHelpers';
import { getCurrentDate, formIsReadOnly, idMatch } from '../../helpers/helpers';
import useStyles from '../../styles/common/formStyles';
import moment from 'moment';
import useToast from '../../hooks/useToast';
import { SEARCH_USERS } from '../../graphql/evalQueries';
import { useApolloClient } from '@apollo/react-hooks';

/* Primary component to render form data */
const Form = React.memo(({ processType, ppeModificationInfo }) => {
  // Context
  const { state } = useFormState();
  const { dispatch } = useFormDispatch();
  const {
    formConfig,
    auditData,
    formResponses,
    formStructure,
    formComments,
    formGoals,
    unsavedEvalChanges
  } = state;
  const { displayToast } = useToast();
  const client = useApolloClient();

  const { formDescription, sections, formTypeId, formType } = formStructure;
  const { status: ppeStatus, sent, view } = formConfig;
  const formIsArchived = () => ppeStatus === STATUS.ARCHIVED.NAME;
  let accessToken = localStorage.getItem('accessToken');
  const firstIndex = 0;

  // State
  const [assignToError, setAssignToError] = useState('');
  const [openModal, setOpenModal] = useState();
  const [selectedReviewer, setSelectedReviewer] = useState();
  const [newComments, setNewComments] = useState([]);
  const [commentsToDelete, setCommentsToDelete] = useState([]);
  const [newResponses, setNewResponses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [archivePending, setArchivePending] = useState(false);
  const [error, setError] = useState(null);
  const [freshPPEid, setFreshPPEid] = useState(null);
  const [
    evaluationForUserCompetency,
    setEvaluationForUserCompetency
  ] = useState();

  // Mutations
  const [submitEval] = useMutation(SUBMIT_EVAL);
  const [saveEval] = useMutation(SAVE_EVAL);
  const [publishEval] = useMutation(PUBLISH_EVAL);
  const [unpublishEval] = useMutation(UNPUBLISH_EVAL);
  const [archiveEval] = useMutation(ARCHIVE_EVAL);
  const [createPPE] = useMutation(CREATE_PPE);
  const [softDeletePPE] = useMutation(SOFT_DELETE_PPE);
  const [upsertResponses] = useMutation(UPSERT_RESPONSES);
  const [upsertComments] = useMutation(UPSERT_COMMENTS);
  const [deleteComments] = useMutation(DELETE_COMMENTS);
  const [archiveData] = useMutation(ARCHIVE_DATA);
  const [upsertNextEvalDate] = useMutation(UPSERT_NEXT_EVAL_DATE_FOR_USER);

  const {
    userData: { name: username, email, role, employeeId },
    refreshLogin
  } = useAuth();

  const getNewToken = async () => {
    const newToken = await refreshLogin();
    return newToken;
  };

  const fetchUsers = (token, searchTerm) => {
    return client.query({
      query: SEARCH_USERS,
      variables: { token, searchTerm }
    });
  };

  const fetchUserCompetencyByJobTitle = jobTitle => {
    return client.query({
      query: FETCH_COMPETENCY_FOR_USER,
      variables: { jobTitle }
    });
  };

  const getUserCompetency = async (token, searchTerm, firstIndex) => {
    const result = await fetchUsers(token, searchTerm).then(resp => {
      const jobTitle = resp.data.searchUsers[firstIndex].jobTitle || '';
      return fetchUserCompetencyByJobTitle(jobTitle);
    });

    const competency =
      result &&
      result.data &&
      result.data.fetchCompetencyForUser &&
      result.data.fetchCompetencyForUser.length !== 0
        ? result.data.fetchCompetencyForUser[firstIndex].udCompetency
        : 'Found';

    return competency;
  };

  const fetchUserDetails = async searchTerm => {
    if (auditData.udCompetency) {
      setEvaluationForUserCompetency(auditData.udCompetency);
      return;
    }

    try {
      const competency = await getUserCompetency(
        accessToken,
        searchTerm,
        firstIndex
      );
      setEvaluationForUserCompetency(competency);
    } catch (error) {
      accessToken = await getNewToken();
      const competency = await getUserCompetency(
        accessToken,
        searchTerm,
        firstIndex
      );
      setEvaluationForUserCompetency(competency);
    }
  };

  useEffect(() => {
    if (auditData.evaluationForEmail) {
      fetchUserDetails(auditData.evaluationForEmail);
    }
  }, [auditData.evaluationForEmail]);

  const { data: lastModifiedData } = useQuery(FETCH_LAST_MODIFIED_DATE, {
    variables: {
      ppeId: formConfig.ppeId,
      evaluationFor: auditData.evaluationFor,
      evaluationForEmail: email,
      evaluationForEmployeeId: employeeId
    },
    skip: !formIsArchived(),
    fetchPolicy: 'no-cache'
  });

  const getLastModifiedOnDate = lastModifiedDate => {
    if (
      lastModifiedDate &&
      lastModifiedDate.length !== 0 &&
      lastModifiedDate.fetchLastModifiedOnDate.length !== 0
    ) {
      const date = lastModifiedDate.fetchLastModifiedOnDate[0].modifiedOn;
      return moment(date).format('L');
    }
    return null;
  };

  const commentsToDeleteRef = useRef();
  commentsToDeleteRef.current = commentsToDelete;

  const history = useHistory();
  const classes = useStyles();

  // Conditionals
  const formIsClosed = () => ppeStatus === STATUS.ARCHIVED.NAME;
  const formIsPublished = () => ppeStatus === STATUS.PUBLISHED.NAME;
  const formTypeIsCraft = () => formTypeId === FORM_TYPE.CRAFT.ID;
  const formTypeIsForeman = () => formTypeId === FORM_TYPE.FOREMAN.ID;
  const formTypeIsIntern = () => formTypeId === FORM_TYPE.INTERN.ID;
  const formTypeIsServiceTech = () => formTypeId === FORM_TYPE.SERVICES_TECH.ID;
  const formTypeIsTechnology = () => formTypeId === FORM_TYPE.TECHNOLOGY.ID;
  const formTypeIsTechnologyLeaders = () =>
    formTypeId === FORM_TYPE.TECHNOLOGY_LEADERS.ID;
  const userIsEvaluated =
    idMatch(email, auditData.evaluationForEmail) ||
    idMatch(employeeId, auditData.evaluationForEmployeeId);
  const submitModalIsOpen = () => openModal === MODAL.SUBMIT;
  const archiveModalIsOpen = () => openModal === MODAL.ARCHIVE;
  const cancelModalIsOpen = () => openModal === MODAL.CANCEL;
  const deleteModalIsOpen = () => openModal === MODAL.DELETE;
  const errorModalIsOpen = () => openModal === MODAL.ERROR;
  const adminIsNotEvaluated = () => userIsAdmin(role) && !userIsEvaluated;
  const deleteButtonAvailable = () =>
    formConfig.ppeId && (adminIsNotEvaluated() || !sent);

  useEffect(() => {
    const changesMade =
      commentsToDelete.length || newResponses.length || newComments.length;
    dispatch({ type: 'setUnsavedEvalChanges', payload: changesMade });
  }, [commentsToDelete, newResponses, newComments, dispatch]);

  const redirectToDashboard = React.useCallback(() => {
    dispatch({ type: 'setAuditData', payload: {} });
    closeModal();
    history.push('/dashboard');
  }, [history, dispatch]);

  const updateResponses = async ppeId => {
    return await upsertResponses({
      variables: {
        input: {
          ppeId,
          newResponses
        }
      }
    });
  };

  const updateNextEvalDate = async (ppeId, archivePending = false) => {
    var nextEvalDateQuestion = formResponses[0].find(
      element => element.questionText === NEXT_EVAL_DATE_QUESTION_TEXT
    );
    var nextEvalDateValue = null;
    if (nextEvalDateQuestion && nextEvalDateQuestion.employeeResponse) {
      nextEvalDateValue = new Date(nextEvalDateQuestion.employeeResponse);
    }
    if (archivePending) {
      nextEvalDateValue = new Date(
        moment()
          .add(1, 'years')
          .format('YYYY-MM-DD')
      );
    }
    if (nextEvalDateValue !== null) {
      return await upsertNextEvalDate({
        variables: {
          userName: auditData.evaluationForEmail,
          nextEvalDate: nextEvalDateValue
        }
      });
    }
  };

  const updateComments = async ppeId => {
    const filterBlankComments = newComments.filter(c => c.comment);
    return await upsertComments({
      variables: {
        input: {
          ppeId,
          newComments: filterBlankComments
        }
      }
    });
  };

  const updateFormEntries = async id => {
    const nextEvalDatePromises = id
      ? await updateNextEvalDate(id)
      : Promise.resolve();
    const responsesPromise = newResponses.length
      ? await updateResponses(id)
      : Promise.resolve();
    const commentsPromises = newComments.length
      ? await updateComments(id)
      : Promise.resolve();
    if (commentsToDeleteRef.current.length) {
      await deleteComments({
        variables: { ids: commentsToDeleteRef.current }
      });
    }
    const [comments] = await Promise.all([
      commentsPromises,
      nextEvalDatePromises,
      responsesPromise
    ]);
    setNewResponses([]);
    setNewComments([]);
    setCommentsToDelete([]);
    return comments ? comments.data.upsertComments : [];
  };

  const handleError = action => {
    setIsLoading(false);
    setError(action);
    setOpenModal(MODAL.ERROR);
  };

  const publishEvaluation = async () => {
    let evalId;
    if (formConfig.ppeId || freshPPEid) {
      evalId = formConfig.ppeId || freshPPEid;
    } else {
      evalId = await createNewPPE(username, email, employeeId, true);
    }

    const publishPromise = publishEval({
      variables: {
        ppeId: evalId,
        evaluationFor: auditData.evaluationFor,
        evaluationForEmail: auditData.evaluationForEmail
      }
    });

    const updatePromise = updateFormEntries(evalId);
    const [addedComments] = await Promise.all([updatePromise, publishPromise]);
    const updatedComments = getUpdatedComments(addedComments);

    dispatch({ type: 'setComments', payload: updatedComments });
    dispatch({ type: 'setFormConfig', payload: { sent: true } });

    setFreshPPEid(evalId);
    return evalId;
  };

  // Handles comment additions or edits in the case of publishing
  const getUpdatedComments = addedComments => {
    const commentCreatorAndSectionMatch = (commentA, commentB) => {
      return (
        commentA.createdByEmail === commentB.createdByEmail &&
        commentA.sectionId === commentB.sectionId
      );
    };
    // update existing comments with any altered comment text
    const updatedComments = formComments.map(comment => {
      const updatedComment = addedComments.find(newComment => {
        return commentCreatorAndSectionMatch(comment, newComment);
      });
      return updatedComment || comment;
    });

    // remove any comments marked for deletion
    const filteredComments = updatedComments.filter(comment => {
      return !commentsToDelete.includes(comment.id);
    });

    // find entirely new comments
    const additionalComments = addedComments.filter(comment => {
      const index = formComments.findIndex(formComment => {
        return commentCreatorAndSectionMatch(comment, formComment);
      });
      return index === -1;
    });

    return [...filteredComments, ...additionalComments];
  };

  const unpublishEvaluation = async () => {
    const evalId = formConfig.ppeId || freshPPEid;

    await unpublishEval({
      variables: {
        ppeId: evalId
      }
    });

    return evalId;
  };

  const handlePublishToggle = async () => {
    let updatedPpe;
    // setTimeout to delay view of loading spinner when we assume there is an error
    setTimeout(() => {
      if (!updatedPpe) {
        setIsLoading(true);
      }
    }, 2000);

    // if doesn't exist, then assign current user
    if (!auditData.assignedTo) {
      dispatch({
        type: 'setAuditDataValue',
        payload: {
          assignedTo: username,
          assignedToEmail: email,
          assignedToEmployeeId: employeeId
        }
      });
    }

    // publish or unpublish depending on toggle state
    try {
      updatedPpe = formIsPublished()
        ? await unpublishEvaluation()
        : await publishEvaluation();

      const newStatus = formIsPublished()
        ? STATUS.OPEN.NAME
        : STATUS.PUBLISHED.NAME;

      dispatch({
        type: 'setFormConfig',
        payload: {
          status: newStatus
        }
      });

      setIsLoading(false);
    } catch {
      handleError(ACTION.PUBLISH);
    }
  };

  const closeModal = () => {
    setIsLoading(false);
    setError(null);
    setOpenModal(null);
  };

  const validateReviewerIsChosen = e => {
    e.preventDefault();
    const target = document.getElementById('downshift-simple-input');
    if (target.value) {
      setAssignToError('');
      setOpenModal(MODAL.SUBMIT);
    } else {
      setAssignToError('Please choose a reviewer.');
    }
  };

  const createNewPPE = async (
    assignName,
    assignEmail,
    userEmployeeId,
    sentValue
  ) => {
    const date = getCurrentDate();
    const { data } = await createPPE({
      variables: {
        status: STATUS.OPEN.ID,
        assignedTo: assignName,
        assignedToEmail: assignEmail,
        assignedToEmployeeId: userEmployeeId || null,
        assignedOn: date,
        evaluationFor: auditData.evaluationFor,
        evaluationForEmail: auditData.evaluationForEmail,
        evaluationForEmployeeId: auditData.evaluationForEmployeeId,
        evaluationForJobNumber: auditData.evaluationForJobNumber,
        evaluationForRole: auditData.evaluationForRole,
        ppeTypeId: formTypeId,
        sent: sentValue,
        jobTitle: auditData.jobTitle,
        udCompetency: evaluationForUserCompetency
      }
    });
    return data.createPPE.id;
  };

  // alter existing comment or add new comment
  const updateCommentValues = (
    commentText,
    sectionId,
    commentId,
    createdOn
  ) => {
    const commentIndex = newComments.findIndex(comment => {
      return (
        (idMatch(comment.createdByEmail, email) ||
          idMatch(comment.createdByEmployeeId, employeeId)) &&
        comment.sectionId === sectionId
      );
    });

    if (commentIndex !== -1) {
      const edited = newComments.map((oldComment, index) => {
        return index === commentIndex
          ? { ...oldComment, comment: commentText }
          : oldComment;
      });
      setNewComments(edited);
    } else {
      const latestComment = {
        createdBy: username,
        createdByEmail: email,
        createdByEmployeeId: employeeId || null,
        sectionId,
        id: commentId,
        comment: commentText,
        createdOn
      };
      setNewComments([...newComments, latestComment]);
    }
  };

  // add or remove comments from deletion array based on checkbox state
  const setCommentsToBeDeleted = React.useCallback((commentId, checked) => {
    if (commentId && checked) {
      setCommentsToDelete(prevCommentsToDelete => [
        ...prevCommentsToDelete,
        commentId
      ]);
    } else {
      setCommentsToDelete(prevCommentsToDelete =>
        prevCommentsToDelete.filter(comment => comment !== commentId)
      );
    }
  }, []);

  // will get reviewers for this evaluation, generate the schema, and add it to the database
  const archiveFinalData = async (
    id,
    createdOn,
    modifiedOn,
    evalResponses,
    evalComments
  ) => {
    if (evalResponses.length || evalComments.length) {
      const token = localStorage.getItem('userData');
      return await archiveData({
        variables: {
          input: {
            ppeId: id,
            formType,
            createdBy: formConfig.createdBy || username,
            createdByEmail: formConfig.createdByEmail || email,
            createdByEmployeeId:
              formConfig.createdByEmployeeId || employeeId || null,
            currentUser: username,
            currentUserEmail: email,
            currentUserEmployeeId: employeeId || null,
            finalAvroResponses: evalResponses,
            finalAvroComments: evalComments,
            goalSnapshot: formGoals.map(goal => ({
              title: goal.title,
              description: goal.description,
              status: goal.statusId.displayName,
              dueDate: goal.dueDate
            })),
            evaluationFor: auditData.evaluationFor,
            evaluationForEmail: auditData.evaluationForEmail,
            evaluationForEmployeeId: auditData.evaluationForEmployeeId || null,
            dateCreated: createdOn,
            dateClosed: modifiedOn,
            formConfig,
            token
          }
        }
      });
    }
    return null;
  };

  // if comments were added/edited prior to archiving, get latest version
  const getEvalComments = upsertedComments => {
    // if any new comments were just upserted, get their extra info
    const editedComments = newComments.map(comment => {
      if (!comment.id && !comment.createdOn) {
        const thisComment = upsertedComments.find(
          upsertedComment =>
            upsertedComment.comment === comment.comment &&
            upsertedComment.createdByEmail === comment.createdByEmail
        );

        return {
          ...comment,
          id: thisComment.id || comment.id,
          createdOn: thisComment.createdOn
        };
      }
      return comment;
    });

    // if there are comments that need to be deleted, remove them
    const existingComments = filterOutDeletedComments(
      formComments,
      commentsToDeleteRef.current
    );

    const uneditedComments = existingComments.filter(comment => {
      return !newComments.includes(comment.id);
    });

    const totalComments = [...uneditedComments, ...editedComments];
    return totalComments.map(comment => formatEvalComment(comment, sections));
  };

  const archiveEvaluation = async () => {
    setArchivePending(true);
    setIsLoading(true);
    let evalId;

    try {
      // ProcessType 2 forms can be immediately archived, ie. created and then set to closed
      if (!freshPPEid && !formConfig.ppeId && processType === 2) {
        evalId = await createNewPPE(username, email, employeeId, true);
      } else {
        evalId = formConfig.ppeId || freshPPEid;
      }

      const commentsPromise = updateFormEntries(evalId);
      const nextEvalPromise = updateNextEvalDate(evalId, true);

      const [comments, nextEvalResult] = await Promise.all([
        commentsPromise,
        nextEvalPromise
      ]);

      const evalComments = getEvalComments(comments);
      const evalResponses = evalFormattedResponses(formResponses, evalId);

      const archiveResult = await archiveEval({
        variables: {
          ppeId: evalId,
          evaluationFor: auditData.evaluationFor,
          evaluationForEmail: auditData.evaluationForEmail
        }
      });

      const { modifiedOn, createdOn } = archiveResult.data.archiveEval;

      // send the data to Document Manager
      await archiveFinalData(
        evalId,
        createdOn,
        modifiedOn,
        evalResponses,
        evalComments
      );
      setArchivePending(false);
      setIsLoading(false);
      setFreshPPEid(evalId);
      redirectToDashboard();
    } catch (error) {
      handleError(ACTION.ARCHIVE);
      const token = await refreshLogin();
      if (evalId) {
        const date = getCurrentDate();
        await submitEval({
          variables: {
            ppeId: evalId,
            evaluationFor: auditData.evaluationFor,
            evaluationForEmail: auditData.evaluationForEmail,
            assignedTo: auditData.assignedTo || username,
            assignedToEmail: auditData.assignedToEmail || email,
            assignedToEmployeeId: auditData.assignedToEmployeeId || employeeId,
            assignedOn: date,
            status: STATUS.OPEN.ID,
            token
          }
        });
      }
      throw error;
    }
  };

  // send an evaluation to a chosen reviewer
  const submitEvaluation = async () => {
    setIsLoading(true);
    let newPPEId;

    const date = getCurrentDate();

    try {
      if (formConfig.ppeId || freshPPEid) {
        newPPEId = formConfig.ppeId || freshPPEid;
        const submitPromise = submitEval({
          variables: {
            ppeId: newPPEId,
            evaluationFor: auditData.evaluationFor,
            evaluationForEmail: auditData.evaluationForEmail,
            assignedTo: auditData.assignedTo,
            assignedToEmail: auditData.assignedToEmail,
            assignedToEmployeeId: auditData.assignedToEmployeeId || null,
            assignedOn: date,
            status: ppeStatus ? STATUS[ppeStatus].ID : STATUS.OPEN.ID
          }
        });

        const updatePromise = updateFormEntries(newPPEId);
        await Promise.all([submitPromise, updatePromise]);
      } else {
        newPPEId = await createNewPPE(
          auditData.assignedTo,
          auditData.assignedToEmail,
          auditData.assignedToEmployeeId,
          true
        );

        await updateFormEntries(newPPEId);
      }
      setFreshPPEid(newPPEId);
      redirectToDashboard();
    } catch {
      handleError(ACTION.SEND);
    }
  };

  // save changes to an evaluation without assigning to a new reviewer
  const saveDraft = async () => {
    setIsLoading(true);
    let currentPPEId;

    try {
      if (formConfig.ppeId || freshPPEid) {
        currentPPEId = formConfig.ppeId || freshPPEid;
        const searchTerm = auditData.evaluationForEmail;
        accessToken = await getNewToken();

        const savePromise = await fetchUsers(accessToken, searchTerm).then(
          resp => {
            const jobTitle = resp.data.searchUsers[firstIndex].jobTitle;
            return saveEval({
              variables: {
                ppeId: currentPPEId,
                jobTitle: jobTitle,
                udCompetency: evaluationForUserCompetency
              }
            });
          }
        );
        const updatePromise = updateFormEntries(currentPPEId);
        await Promise.all([savePromise, updatePromise]);
      } else {
        currentPPEId = await createNewPPE(username, email, employeeId, false);
        await updateFormEntries(currentPPEId);
      }
      setFreshPPEid(currentPPEId);
      redirectToDashboard();
    } catch {
      handleError(ACTION.SAVE);
    }
  };

  const deleteEvaluation = async () => {
    setIsLoading(true);

    try {
      await softDeletePPE({
        variables: {
          ppeId: formConfig.ppeId
        }
      });
      redirectToDashboard();
    } catch {
      handleError(ACTION.DELETE);
    }
  };

  const renderFormQuestions = (
    sectionId,
    questionArray,
    sectionDescription,
    sectionTitle
  ) => {
    // filter removes redundant Executive Comments section title and question title
    let filteredQuestions;
    if (sectionTitle === 'Executive Comments') {
      filteredQuestions = questionArray.filter(
        q => q.questionText !== sectionTitle
      );
    }

    const getQuestionsAndDescriptionsByCompetency = (
      sectionQuestions,
      competency
    ) => {
      const competencyProperties = ['Found', 'Mid', 'Upper', 'Exec'];

      const filteredSectionQuestions = sectionQuestions.filter(question => {
        // Check if the question has any of the competency properties
        const hasCompetencyProperty = competencyProperties.some(prop =>
          question.hasOwnProperty(prop)
        );
        // If it has the competency property, check if it matches the userCompetency
        if (hasCompetencyProperty) {
          return question.hasOwnProperty(competency);
        }
        // If it doesn't have any of the competency properties, include it
        return true;
      });

      const sectionQuestionsToDisplay = evaluationForUserCompetency
        ? filteredSectionQuestions
        : sectionQuestions;

      return sectionQuestionsToDisplay.map(question => {
        if (question[competency]) {
          return {
            questionId: question.questionId,
            questionText:
              question[competency][`${competency.toLowerCase()}QuestionText`],
            answerType: question.answerType,
            answerOptions: question.answerOptions,
            questionDescription:
              question[competency][
                `${competency.toLowerCase()}QuestionDescription`
              ]
          };
        }
        return question;
      });
    };

    return (
      filteredQuestions ||
      getQuestionsAndDescriptionsByCompetency(
        questionArray,
        evaluationForUserCompetency
      )
    ).map(question => (
      <QuestionContainer
        key={question.questionId}
        text={question.questionText}
        questionDescription={question.questionDescription}
        type={question.answerType}
        options={question.answerOptions ? question.answerOptions : null}
        questionId={question.questionId}
        sectionId={sectionId}
        description={sectionDescription}
        sectionTitle={sectionTitle}
        newResponses={newResponses}
        setNewResponses={setNewResponses}
      />
    ));
  };

  const renderGoals = () => {
    return (
      <div className={classes.goalsContainer}>
        <h4 className={classes.activeGoalsHeader}>
          Active and Completed Goals
        </h4>
        <Grid container spacing={1}>
          {!formConfig.ppeId ? (
            <Typography className={classes.noGoalsText}>
              To import active goals or goals completed within the last 12
              months into this evaluation, please click the 'Save Draft' button
              below and then reopen the evaluation.
            </Typography>
          ) : formConfig.ppeId && !formGoals.length ? (
            <Typography className={classes.noGoalsText}>
              No goals to display.
            </Typography>
          ) : (
            formGoals.map(goal => (
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography className={classes.goalTitle}>
                      {goal.title}
                    </Typography>
                    <Typography className={classes.goalMetadata}>
                      {goal.statusId.displayName}, due{' '}
                      {goal.dueDate
                        ? moment(goal.dueDate).format('L')
                        : 'date not set'}
                    </Typography>
                    <Typography className={classes.goalDescription}>
                      {goal.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      </div>
    );
  };

  const renderInternFormSections = sectionsArray => {
    return sectionsArray.map(section => {
      if (
        section.sectionTitle === 'Performance' ||
        section.sectionTitle === 'Attendance'
      ) {
        // questions are split across two columns
        const halfLength = Math.ceil(section.sectionQuestions.length / 2);
        const leftHalf = section.sectionQuestions.slice(0, halfLength);

        const rightHalfArray = section.sectionQuestions.slice(
          halfLength,
          section.sectionQuestions.length
        );

        return (
          <section key={section.sectionId}>
            <h3>{section.sectionTitle}</h3>
            <hr />
            <Grid container className={classes.gridContainer}>
              <div className={classes.leftSideIntern}>
                {renderFormQuestions(
                  section.sectionId,
                  leftHalf,
                  section.sectionDescription,
                  section.sectionTitle
                )}
              </div>
              <Divider orientation="vertical" className={classes.divider} />
              <div className={classes.rightSideIntern}>
                {renderFormQuestions(
                  section.sectionId,
                  rightHalfArray,
                  section.sectionDescription,
                  section.sectionTitle
                )}
              </div>
            </Grid>
          </section>
        );
      }

      if (
        section.sectionTitle === 'Supervisor' ||
        section.sectionTitle === 'Intern'
      ) {
        return (
          <section key={section.sectionId}>
            <h3>{section.sectionTitle}</h3>
            <hr />
            <div
              className={
                section.sectionTitle === 'Intern'
                  ? classes.internSignOff
                  : classes.generalInfo
              }
            >
              {renderFormQuestions(
                section.sectionId,
                section.sectionQuestions,
                section.sectionDescription,
                section.sectionTitle
              )}
            </div>
          </section>
        );
      }

      return (
        <section key={section.sectionId}>
          {section.sectionId === 4 && (
            <div>
              <h3>Additional Feedback</h3>
              <hr />
            </div>
          )}
          {renderFormQuestions(
            section.sectionId,
            section.sectionQuestions,
            section.sectionDescription,
            section.sectionTitle
          )}
          {section.sectionId !== 8 && renderCommentSection(section.sectionId)}
        </section>
      );
    });
  };

  const renderCompetencyHeader = (formType, sectionTitle, sectionQuestions) => {
    const removeCompetencyHeader = sectionQuestions.every(
      question => question.answerType === 'TextField'
    );

    const competencyUrl = getCompetencyUrl(evaluationForUserCompetency);
    const competencyUrlToDisplay = (
      <Link href={competencyUrl} target="_blank" rel="noopener noreferrer">
        (View Descriptions)
      </Link>
    );

    //TODO: remove isBetaForm check once we get rid of the beta forms
    const isBetaForm = formType.includes('Beta');
    if (!removeCompetencyHeader) {
      if (
        (isBetaForm ||
          formTypeIsTechnology() ||
          formTypeIsTechnologyLeaders()) &&
        sectionTitle === 'Leadership Skills'
      ) {
        return (
          <p className={classes.competency}>
            Competency {competencyUrlToDisplay}
          </p>
        );
      }
      return null;
    }
    return null;
  };

  const renderSections = sectionsArray => {
    // these form types only have a comment section after the very last form section
    if (formTypeIsCraft() || formTypeIsForeman() || formTypeIsServiceTech()) {
      return sectionsArray.map(section => {
        return (
          <section key={section.sectionId}>
            <h3>{section.sectionTitle}</h3>
            {section.showGoals && renderGoals()}
            <hr />
            {renderFormQuestions(
              section.sectionId,
              section.sectionQuestions,
              section.sectionDescription
            )}
            {section.sectionId === sectionsArray.length &&
              renderCommentSection(section.sectionId)}
          </section>
        );
      });
    }
    return sectionsArray.map(section => {
      return (
        <section key={section.sectionId}>
          <h3>{section.sectionTitle}</h3>
          <div>
            {section.showGoals && renderGoals()}
            {renderCompetencyHeader(
              formType,
              section.sectionTitle,
              section.sectionQuestions
            )}
            <hr />
            {renderFormQuestions(
              section.sectionId,
              section.sectionQuestions,
              null,
              section.sectionTitle
            )}
          </div>
          {renderCommentSection(section.sectionId, section.sectionTitle)}
        </section>
      );
    });
  };

  // the first section consists of the evaluee info fields
  const renderAuditFields = sectionsArray => {
    return sectionsArray[0].sectionQuestions.map(question => {
      return (
        <Fragment>
          <AuditField
            key={question.questionId}
            questionText={question.questionText}
            questionType={question.answerType}
            questionId={question.questionId}
            sectionId={0}
            newResponses={newResponses}
            setNewResponses={setNewResponses}
          />
          {question.questionText === NEXT_EVAL_DATE_QUESTION_TEXT &&
            role === 'Admin' && (
              <Button
                onClick={() =>
                  updateNextEvalDate(formConfig.ppeId)
                    .catch(e => {
                      displayToast(
                        'Error: Something went wrong while trying to update Next Evaluation Date. Please try again. If the problem persists, please contact support.',
                        'error'
                      );
                      setIsLoading(false);
                      throw e;
                    })
                    .then(r => {
                      displayToast(
                        'Next Evaluation Date successfully updated.',
                        'success'
                      );
                      setIsLoading(false);
                    })
                }
                variant="contained"
                className={classes.updateNextEvalDateBtn}
                data-cy="updateNextEvalDateBtn"
              >
                Update Next Evaluation Date
              </Button>
            )}
        </Fragment>
      );
    });
  };

  if (isLoading) {
    return <LoadingSpinner archivePending={archivePending} />;
  }

  const renderCommentSection = (sectionId, sectionTitle) => {
    return (
      <CommentSection
        errorOccurred={error !== null}
        sectionId={sectionId}
        sectionTitle={sectionTitle}
        setCommentsToBeDeleted={setCommentsToBeDeleted}
        updateCommentValues={updateCommentValues}
        updatedComments={newComments}
      />
    );
  };

  // reviewers and managers cannot archive unless the form is of process type 2
  const archiveButtonIsHidden = () => {
    if ((userIsPrimary(role) || userIsManager(role)) && processType !== 2) {
      return true;
    }
    return userIsEvaluated;
  };

  const archiveClassName = () => {
    if (role === 'Admin') {
      return classes.archiveBtnUnpublished;
    }

    if (formIsClosed()) return classes.archiveBtnPublished;

    // button is hidden for regular users and execs/admins viewing their own evaluations
    if (archiveButtonIsHidden()) {
      if (formIsPublished()) {
        return classes.archiveBtnHiddenPublished;
      }
      return classes.archiveBtnHiddenUnpublished;
    }

    // button is greyed out if evaluation is published, new, or read-only
    if (
      ((formIsPublished() || !formConfig.ppeId) && processType !== 2) ||
      userIsEvaluated ||
      formIsReadOnly(
        email,
        auditData.assignedToEmail,
        employeeId,
        auditData.assignedToEmployeeId,
        view
      )
    ) {
      return classes.archiveBtnPublished;
    }

    return classes.archiveBtnUnpublished;
  };

  const renderInternshipField = sectionsArray => {
    return sectionsArray.map(section => {
      if (section.sectionTitle === 'Internship') {
        return renderFormQuestions(
          section.sectionId,
          section.sectionQuestions,
          section.sectionDescription,
          section.sectionTitle
        );
      }
      return null;
    });
  };

  return (
    <>
      <div className={classes.root}>
        <StatusBanner
          info={ppeModificationInfo}
          modifiedOn={getLastModifiedOnDate(lastModifiedData)}
        />
        <AutoScroll type="scrollToTop" />

        <h1 className={classes.formHeader}>{formDescription}</h1>

        <section className={classes.employeeInfo}>
          {sections && renderAuditFields(sections)}
          {sections && renderInternshipField(sections)}
        </section>
        {!formTypeIsIntern() && sections && <RatingScale sections={sections} />}
        <section>
          {formTypeIsIntern()
            ? sections && renderInternFormSections(sections.slice(2))
            : sections && renderSections(sections.slice(1))}
          <div className={classes.actionContainer}>
            <PrintPDFButton closed={formIsClosed()} />
            {deleteButtonAvailable() && (
              <div className={classes.actionLinkContainer}>
                <DeleteForever className={classes.actionIcon} />
                <Link
                  component="button"
                  variant="body2"
                  data-cy="deleteBtn"
                  onClick={() => setOpenModal(MODAL.DELETE)}
                  className={
                    formIsClosed()
                      ? `${classes.pdfLinkClosedView} ${classes.actionLink}`
                      : classes.actionLink
                  }
                >
                  Delete Evaluation
                </Link>
              </div>
            )}
          </div>
          {!formIsClosed() &&
            !userIsEvaluated &&
            !formIsReadOnly(
              email,
              auditData.assignedToEmail,
              employeeId,
              auditData.assignedToEmployeeId,
              view
            ) && <PublishSection handlePublishToggle={handlePublishToggle} />}
          <div className={classes.assignContainer}>
            <AssignToField
              setReviewer={setSelectedReviewer}
              setError={setAssignToError}
              error={assignToError}
              disabled={formIsPublished()}
            />
          </div>
        </section>
      </div>
      <section className={classes.buttonContainer}>
        <div className={classes.leftBtnGroup}>
          <Button
            onClick={
              unsavedEvalChanges
                ? () => setOpenModal(MODAL.CANCEL)
                : () => redirectToDashboard()
            }
            variant="contained"
            disabled={formIsPublished()}
            className={`${classes.button} ${classes.cancelBtn}`}
            data-cy="cancelBtn"
          >
            <CancelOutlined />
            CANCEL
          </Button>
          <Button
            onClick={() => setOpenModal(MODAL.ARCHIVE)}
            variant="contained"
            className={`${classes.button} ${archiveClassName()}`}
            data-cy="archiveBtn"
            disabled={
              formIsClosed() ||
              (role !== 'Admin' &&
                (formIsPublished() ||
                  formIsReadOnly(
                    email,
                    auditData.assignedToEmail,
                    employeeId,
                    auditData.assignedToEmployeeId,
                    view
                  ) ||
                  ((userIsPrimary(role) || !formConfig.ppeId) &&
                    processType !== 2)))
            }
          >
            <ArchiveOutlined />
            ARCHIVE
          </Button>
        </div>
        <div className={classes.rightBtnGroup}>
          <Button
            onClick={validateReviewerIsChosen}
            variant="contained"
            id="ppeSubmit"
            disabled={formIsPublished()}
            className={`${classes.button} ${classes.sendBtn}`}
            data-cy="submitPPE"
          >
            <SendOutlined />
            SEND
          </Button>
          <Button
            onClick={saveDraft}
            variant="contained"
            disabled={formIsClosed() || formIsPublished()}
            className={`${classes.button} ${classes.saveDraftBtn}`}
            data-cy="saveBtn"
          >
            <SaveOutlined />
            SAVE DRAFT
          </Button>
        </div>
      </section>
      <AutoScroll type="scrollToBottom" />
      {/* Modals */}
      {submitModalIsOpen() && (
        <SubmitModal
          close={closeModal}
          action={submitEvaluation}
          reviewer={selectedReviewer}
        />
      )}
      {archiveModalIsOpen() && (
        <ArchiveModal close={closeModal} action={archiveEvaluation} />
      )}
      {cancelModalIsOpen() && (
        <CancelModal close={closeModal} action={redirectToDashboard} />
      )}
      {deleteModalIsOpen() && (
        <DeleteModal close={closeModal} action={deleteEvaluation} />
      )}
      {errorModalIsOpen() && <ErrorModal close={closeModal} error={error} />}
    </>
  );
});

Form.propTypes = {
  processType: PropTypes.number.isRequired,
  ppeModificationInfo: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Form;
